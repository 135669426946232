import React from 'react'

const LabelInput = ({ text, isRequire }) => {
    return <React.Fragment>
        <div className="module labelInput">
            <div className="label-input-wrapper">
                <label>{text}</label>{isRequire ? <span>*</span> : ""}
            </div>
        </div>
    </React.Fragment>
}

export default LabelInput