
import React from 'react'
import MyPage from '../../core/components/MyPage'

class LayoutBlank extends MyPage {
    // constructor(props) {
    //     super(props)
    // }

    componentDidMount() {
        let vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', `${vh}px`);
        window.addEventListener('resize', this.resize);
    }
    componentWillUnmount() {
        window.removeEventListener('resize', this.resize);
    }
    resize = () => {
        let vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', `${vh}px`);
        this.forceUpdate()
    }

    render() {
        try {
            return (
                <React.Fragment>
                    <div className="module layoutBlank">
                        {this.props.children}
                    </div>
                </React.Fragment>
            )
        } catch (error) {
            return this.renderErrorComponent(error)
        }
    }
}


LayoutBlank.defaultProps = {

}



export default LayoutBlank