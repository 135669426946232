import moment from 'moment'
import ErrorModel from '../core/model/ErrorModel'

export const CALL_API = Symbol('CALL_API')

const resStatusError = [400, 401, 402, 403, 404, 422, 500, 502, 503]

function getAction(type, action, state, res) {
    return new Promise((resolve, reject) => {
        if (!res) return resolve({ type })
        let errors = []
        if (!!state.errors && Array.isArray(state.errors)) {
            errors.concat(state.errors)
        }
        if (resStatusError.includes(res.status)) {
            if (res.status === 422) {
                return res.json().then(json => {
                    var message = ""
                    for (const key in json) {
                        if (json.hasOwnProperty(key)) {
                            const element = json[key];
                            for (const k in element) {
                                if (element.hasOwnProperty(k)) {
                                    const ele = element[k];
                                    message += `${ele.msg}`
                                    if (ele.param) message += ` Param : ${ele.param} `
                                    message += "\n"
                                }
                            }

                        }
                    }

                    return reject(
                        {
                            errors: [
                                new ErrorModel({
                                    status: "422",
                                    code: "422",
                                    message: message
                                })
                            ],
                            errorList: errors
                        }
                    )
                })
            } else if (res.status === 404) {
                return reject(
                    {
                        errors: [
                            new ErrorModel({
                                status: "404",
                                code: "404",
                                message: "404 (Not Found)"
                            })
                        ],
                        errorList: errors
                    }
                )
            } else if (res.status === 401) {
                return reject(
                    {
                        errors: [
                            new ErrorModel({
                                status: "401",
                                code: "401",
                                message: "401 (Unauthorized)"
                            })
                        ],
                        errorList: errors
                    }
                )
            } else {
                return res.json().then(data =>
                    reject(
                        {
                            errors: [
                                new ErrorModel({
                                    status: res.statusText.toString(),
                                    code: data ? data.code : "",
                                    message: data ? data.message : ""
                                })
                            ],
                            errorList: errors
                        }
                    )
                )
            }
        }

        if (typeof type === 'object') {
            if (type.payload) {
                let payload = null
                if (typeof type.payload === "function") {
                    payload = type.payload(action, state, res)
                } else {
                    payload = type.payload
                }
                if (typeof payload.then === 'function') { //promise
                    let payloadData = null
                        , returnFuntion = reject
                        , returnData = {
                            errorList: errors
                        }

                    return payload.then(data => {
                        if (!!data && typeof data.result === "boolean") {
                            if (data.result === true) {
                                returnFuntion = resolve
                                if (!!data.data) {
                                    payloadData = data.data
                                } else if (Array.isArray(data.datas)) {
                                    payloadData = data.datas
                                }
                                returnData.payload = payloadData
                                returnData.type = type.type
                            } else {
                                if (Array.isArray(data.errors)) {
                                    payloadData = []
                                    data.errors.forEach(err => {
                                        payloadData.push(
                                            new ErrorModel({
                                                status: "",
                                                code: err ? err.code : "",
                                                message: err ? err.message : ""
                                            })
                                        )
                                    });
                                }
                                returnData.errors = payloadData
                            }
                        } else {
                            returnData.errors = [
                                new ErrorModel({
                                    status: "",
                                    code: "00000000",
                                    message: "Something went wrong. Please try again later."
                                })
                            ]
                        }

                        return returnFuntion(returnData)
                        // return resolve({ type: type.type, payload: payloadData, errorList: errors })
                    }).catch(err => {
                        returnData.errors = [
                            new ErrorModel({
                                status: "",
                                code: "00000001",
                                message: "Something went wrong. Please try again later."
                            })
                        ]
                        return returnFuntion(returnData)
                    })
                } else {
                    return resolve({ type: type.type, payload, errorList: errors })
                }
            }
            return resolve({ type: type.type, errorList: errors })
        }

        return res.json().then(payload => resolve({ type, payload, errorList: errors }))
    })
}

const API = ({ getState }) => next => action => {
    if (typeof action[CALL_API] !== 'object') return next(action)

    const {
        endpoint,
        method = 'GET',
        headers,
        body,
        types
    } = action[CALL_API]

    const [request, success, failure] = types
    const { callBack } = success
    const state = getState()

    getAction(request, action, state).then(action => next(action))

    let startRequestTime = moment()

    if (callBack) {
        fetch(endpoint, { method, headers, body })
            .then(res => getAction(success, action, state, res))
            .then(action => {
                callBack(action)
                if (moment().diff(startRequestTime, 'millisecond') < 500) {
                    setTimeout(() => {
                        next(action)
                    }, 500)
                } else {
                    next(action)
                }
            })
            .catch((err, a) => {
                if (moment().diff(startRequestTime, 'millisecond') < 500) {
                    setTimeout(() => {
                        next({ type: failure, payload: err.errors, errorList: err.errors })
                    }, 500)
                } else {
                    next({ type: failure, payload: err.errors, errorList: err.errors })
                }
            })
    } else {
        fetch(endpoint, { method, headers, body })
            .then(res => getAction(success, action, state, res))
            .then(action => {
                if (moment().diff(startRequestTime, 'millisecond') < 500) {
                    setTimeout(() => {
                        next(action)
                    }, 500)
                } else {
                    next(action)
                }
            })
            .catch((err, a) => {
                if (moment().diff(startRequestTime, 'millisecond') < 500) {
                    setTimeout(() => {
                        next({ type: failure, payload: err.errors, errorList: err.errors })
                    }, 500)
                } else {
                    next({ type: failure, payload: err.errors, errorList: err.errors })
                }
            })
    }
}

export default API