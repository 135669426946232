
import React from 'react'
import { withRouter, Route, Redirect } from 'react-router-dom'
import { compose } from 'recompose'
import RoutePermission from './RoutePermission'

class PublicRoute extends RoutePermission {
    render() {
        let pathCurrent = this.getPathCurrent()
        if (this.isAuthenticated() && pathCurrent === "/login") {
            return <Redirect to={`/`} />
        }
        let data = this.getComponentRoute()
        return <Route {...data.props} path={data.path} component={data.Component} />
    }
}

PublicRoute.defaultProps = {
    component: null,
    path: "",
}

const enhance = compose(
    withRouter,
)

export default enhance(PublicRoute)